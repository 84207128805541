<template>
  <div class="stepsdemo-content">
    <!-- <Toast position="top-right" /> -->
    <ConfirmDialog></ConfirmDialog>
    <Dialog :visible="displayEditModal" :closable="false" :style="{ width: '25vw' }" :modal="true">
      <template #header>
        <div class="p-d-flex p-flex-column" :style="{ width: '100%' }" v-if="displayEditModalData">
          <h4 :style="{ 'margin-bottom': '0' }">Mise a jour</h4>
          <Divider />
        </div>
        <div class="p-d-flex p-flex-column" :style="{ width: '100%' }" v-else>
          <h4 :style="{ 'margin-bottom': '0' }">Ajout d'une formation</h4>
          <Divider />
        </div>
      </template>
      <div>
        <div class="p-fluid p-d-flex-column p-jc-start" :style="{ gap: '1em' }">
          <div class="field p-mb-4">
            <div class="p-d-flex p-ai-center p-jc-between" :style="{ gap: '1em' }">
              <label
                class="p-ml-2"
                :style="{ display: 'inline-flex', 'overflow-wrap': 'break-word', 'inline-size': '17em' }"
                for="firstname"
              >
                Nom de la formation :
              </label>
              <!-- <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-2em">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg> -->
            </div>
            <InputText :style="{ 'margin-top': '1em' }" id="title" v-model="training.title" />
            <!-- <small class="p-error">Requis</small> -->
          </div>
          <div class="p-d-flex p-jc-between" :style="{ gap: '1em' }">
            <div class="field p-mb-4" :style="{ width: '40%' }">
              <div class="p-d-flex p-ai-center p-jc-between" :style="{ gap: '1em' }">
                <label class="p-ml-2" for="firstname"> Nombre d'heures : </label>
                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-2em">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>
              </div>
              <InputNumber 
              showButtons :style="{ 'margin-top': '1em' }" id="nbHours" v-model="training.nbHours" />
              <!-- <small class="p-error">Requis</small> -->
            </div>
            <div class="field p-mb-4" :style="{ width: '60%' }">
              <div class="p-d-flex p-ai-center p-jc-between" :style="{ gap: '1em' }">
                <label class="p-ml-2" for="firstname"> {{ isRapport ? 'Nombre de sénégalais à former' : 'Nombre de sénégalais formés' }} : </label>
                <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-2em">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>
              </div>
              <InputNumber
                showButtons
                :style="{ 'margin-top': '1em' }"
                id="nbPersons"
                v-model="training.nbPersons"
              />
              <!-- <small class="p-error">Requis</small> -->
            </div>
          </div>
        </div>
      </div>

      <template #footer>
        <Button label="Annuler" @click="closeNewTraining()" icon="pi pi-times" class="p-button-text" />
        <Button v-if="displayEditModalData" label="Valider" @click="saveEditModal()" icon="pi pi-check" autofocus />
        <Button
          v-if="!displayEditModalData"
          label="Soumettre"
          @click="submitNewTraining()"
          icon="pi pi-check"
          autofocus
        />
      </template>
    </Dialog>
    <Card>
      <template #content>
        <!-- {{ trainingsQuestionsListMock }} -->
        <!-- {{ adminrefactoListAnswers }} -->
        <!-- {{ getPlanInfos?.trainings }} -->
        <div class="p-fluid" :style="{ width: '100%', gap: '1em' }">
          <div class="p-fluid p-d-flex p-jc-between" :style="{ gap: '1em' }" v-if="(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole())">
            <div class="field p-mb-4" v-for="train in adminrefactoListAnswers" :key="train.id">
              <div class="p-d-flex p-ai-center p-jc-between" :style="{ gap: '1em' }">
                <div  @click="selectToReturn({toReturn: train})" v-if="((this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) && (getPlanList?.data[0].status === 'F_SUBMITTED'))">
                  <span v-if="this.getToReturn.filter(e=>e.id === train.answers.id).length > 0">
                    <input
                        id="checkbox"
                        type="checkbox"
                        :checked="true"
                    />
                  </span>
                  <span v-else>
                    <input
                        id="checkbox"
                        type="checkbox"
                        :checked="false"
                    />
                  </span>
                </div>
                <div>
                  <label
                    class="p-ml-2"
                    :style="{ display: 'inline-flex', 'overflow-wrap': 'break-word', 'inline-size': '17em' }"
                    for="firstname"
                  >
                    {{ train.title }}
                  </label>
                  <p class="p-ml-2 p-mt-1" :style="{ color: '#000', padding:'.3em', backgroundColor: '#eee', fontStyle: 'bold' }">
                    
                    {{ train.answers.forecast }}
                  </p>
                </div>
              </div>
              <!-- <InputNumber showButtons :style="{ 'margin-top': '1em' }" id="forecast" v-model="train.answers.forecast" /> -->
              <!-- <small class="p-error">Requis</small> -->
            </div>
          </div>
          <div class="p-fluid p-d-flex p-jc-between" :style="{ gap: '1em' }" v-else>
            <div class="field p-mb-4" v-for="train in refactoListAnswers" :key="train.id">
              <div class="p-d-flex p-ai-center p-jc-between" :style="{ gap: '1em' }">
                <div>
                  <label
                    class="p-ml-2"
                    :style="{ display: 'inline-flex', 'overflow-wrap': 'break-word', 'inline-size': '17em' }"
                    for="firstname"
                  >
                    {{ train.title }}
                  </label>
                  <p class="p-ml-2 p-mt-1" :style="{ color: '#000', padding:'.3em', backgroundColor: '#eee', fontStyle: 'bold' }">
                    
                    <InputNumber
                      decrementButtonClass="bg-blue-300"
                      incrementButtonClass="bg-blue-300"
                      v-if="
                      (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
                      (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))"
                      required
                      :min="0"
                      :class="{ 'p-invalid': this.invalidQuestionIds.includes(train.answers.questionId) }"
                      showButtons
                      :style="{ 'margin-top': '1em' }"
                      id="nbHours"
                      v-model="train.answers.forecast"
                    />
                    <span v-else  :style="{ color: '#000', padding:'.3em', backgroundColor: '#eee', fontStyle: 'bold' }">
                      {{ train.answers.forecast }}
                    </span>
                  </p>
                </div>
              </div>
              
          <p>
            <small
              :style="{ color: '#ef3636', fontStyle: 'italic', marginLeft: '0.5em' }"
              v-if="this.invalidQuestionIds.includes(train.answers.questionId)"
              >La valeur doit être supérieure ou égale à 0.</small
            >
          </p>
              <!-- <InputNumber showButtons :style="{ 'margin-top': '1em' }" id="forecast" v-model="train.answers.forecast" /> -->
              <!-- <small class="p-error">Requis</small> -->
            </div>
          </div>
          <p             v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_CREATED') && (getPlanList?.data[0].status !== 'F_REJECTED'))">
          <small :style="{ color: '#ef3636', fontStyle: 'italic' }"
            >Toutes les valeurs de cette section sont requises (*).
          </small>
        </p>
          <!-- {{ trainingsListMock }} -->
          <DataTable editMode="cell" :value="(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) ? getPlanInfos?.trainings : trainingsListMock" responsiveLayout="scroll" :style="{ width: '100%' }">
            <template #header>
              <div class="table-header p-d-flex p-jc-between p-ai-center p-mt-2">
                <h4 :style="{ padding: '0', margin: '0' }">Formations</h4>
                <Button
                v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))"
                :style="{ width: '10em' }" @click="openCreateModal()" label="Ajouter une formation" icon="pi pi-plus" />
              </div>
            </template>
            <!-- <Column field="check" :style="{ width: '2.5em' }">
              <template #body="slotProps">
                <Checkbox v-model="slotProps.data.selected" :binary="true" />
              </template>
            </Column> -->
            <Column field="name" header="Nom de la formation">
              <template #body="slotProps">
                <div class="p-d-flex p-ai-center">
                  <span class="p-ml-2">{{ slotProps.data.title }}</span>
                </div>
              </template>
            </Column>
            <Column field="validity" :style="{ width: '3em' }">
              <template #body="slotProps">
                <i v-if="slotProps.data.validity" class="pi pi-info-circle" style="font-size: 1.5rem"></i>
              </template>
            </Column>
            <Column field="nbHours" header="Nombre d'heures">
              <template #body="slotProps">
                <div class="p-ml-3">
                  <span v-text="slotProps.data.nbHours"></span>
                </div>
                <!-- <span>{{slotProps.data.number}}</span> -->
              </template>
            </Column>
            <Column field="nbPersons" :header="isRapport ? 'Nombre de sénégalais à former' : 'Nombre de sénégalais formés'">
              <template #body="slotProps">
                <div>
                  <span v-text="slotProps.data.nbPersons"></span>
                </div>
              </template>
            </Column>
            <Column :exportable="false"           v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))">
              <template #body="slotProps">
                <div class="p-d-flex p-jc-between">
                  <Button
                    icon="pi pi-pencil"
                    label="Editer"
                    class="p-button-raised p-button-secondary p-button-text p-mr-2"
                    @click="openEditModal(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-trash"
                    label="Supprimer"
                    class="p-button-raised p-button-danger p-button-text p-mr-2"
                    @click="confirmDeleteTraining(slotProps.data.id)"
                  />
                  <!-- <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />
                          <Button icon="pi pi-download" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" /> -->
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
      <template #footer>
        <div class="p-fluid p-d-flex p-jc-start" :style="{ gap: '1em', 'margin-top': '2em' }">
          <Button
            label="Retour"
            @click="gotoSalaryStep()"
            icon="pi pi-angle-left"
            iconPos="left"
            :style="{ width: '20%' }"
          />
          <Button
            label="Enregistrer les informations"
            v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))"
            @click="saveAnswers()"
            :style="{ width: '40%' }"
            icon="pi pi-check"
            iconPos="right"
          />
          <Button
            label="Suivant"
            @click="gotoSupplyStep()"
            :style="{ width: '20%' }"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import localContentTrainingMixin from '@/mixins/local-content-training-mixin';
export default {
  mixins: [localContentTrainingMixin],
  data() {
    return {
      isRapport:false,
      training: {
        id: 0,
        title: '',
        nbHours: 0,
      },
      displayEditModalData: [],
      displayEditModal: false,
      trainingsQuestionsListMock: {},
      trainingsAnswersListMock: {},
      trainingsListMock: {},
      refactoListAnswers: [],
      invalidQuestionIds: [],
      adminrefactoListAnswers: [],
    };
  },
  // computed: {
  //   ...mapGetters({
  //     getTrainingsQuestionsListMock: 'localContent/getTrainingsQuestionsListMock',
  //     getTrainingsAnswersListMock: 'localContent/getTrainingsAnswersListMock',
  //     getTrainingsInTable: 'localContent/getTrainingsInTable',
  //   }),
  // },
  // mounted() {
  //   this.fetchAllTrainings();
  //   this.fetchAllTrainingsInTable();
  // },
  // watch: {
  //   getTrainingsQuestionsListMock(newTrainingsQuestions, oldTrainingsQuestions) {
  //     this.trainingsQuestionsListMock = newTrainingsQuestions;
  //     console.log(oldTrainingsQuestions);
  //   },
  //   getTrainingsAnswersListMock(newTrainingsAnswers, oldTrainingsAnswers) {
  //     this.trainingsAnswersListMock = newTrainingsAnswers;
  //     this.setRefactoList();
  //     console.log(oldTrainingsAnswers);
  //   },
  //   getTrainingsInTable(newTraining, oldTraining) {
  //     this.trainingsListMock = newTraining;
  //     console.log(oldTraining);
  //   },
  // },
  // methods: {
  //   ...mapActions({
  //     fetchAllTrainings: 'localContent/fetchAllTrainings',
  //     fetchAllTrainingsInTable: 'localContent/fetchAllTrainingsInTable',
  //     submitTraining: 'localContent/submitTraining',
  //     deleteTraining: 'localContent/deleteTraining',
  //     submitAnswers: 'localContent/submitAnswers'
  //   }),
  //   toggleJobsComment(event, slot) {
  //     console.log(this.$refs);
  //     if (slot.questionId) {
  //       this.$refs['comment_' + slot.questionId].toggle(event);
  //     } else {
  //       this.$refs['comment_' + slot.id].toggle(event);
  //     }
  //   },
  //   setRefactoList() {
  //     this.refactoListAnswers = [];
  //     this.trainingsQuestionsListMock.forEach((element) => {
  //       let hasAnswer = [];
  //       hasAnswer = this.trainingsAnswersListMock.filter((e) => {
  //         return e.questionId === element.id;
  //       })?.[0];

  //       //BUILD LIST OF ANSWERS FROM QUESTIONS LIST
  //       this.refactoListAnswers.push({
  //         answers: {
  //           questionId: element.id,
  //           ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
  //           forecast: hasAnswer?.forecast || 0,
  //           real: hasAnswer?.real || 0,
  //           comment: hasAnswer?.comment || '',
  //         },
  //         title: element.title,
  //         year: 2023,
  //       });
  //     });
  //   },
  //   saveAnswers() {
  //     let Obj = {
  //       answers: [],
  //       year: 2023,
  //     };
  //     this.refactoListAnswers.forEach((element) => {
  //       Obj.answers.push(element.answers);
  //     });
  //     console.log(JSON.stringify(Obj));
  //     this.submitAnswers(Obj).then((e) => {
  //       this.$toast.add({
  //         severity: 'success',
  //         summary: 'Mise à jour',
  //         detail: 'Mise à jour effectuée avec success',
  //         life: 7000,
  //       });
  //       console.log(e);
  //     });
  //   },
  //   saveEditModal() {
  //     const train = JSON.stringify({
  //       training: {
  //         id: this.training.id,
  //         title: this.training.title,
  //         nbHours: this.training.nbHours,
  //       },
  //       year: 2023,
  //     });
  //     this.submitTraining(train).then((e) => {
  //       this.closeNewTraining();
  //       console.log(e);
  //     });
  //   },
  //   submitNewTraining() {
  //     const train = JSON.stringify({
  //       training: {
  //         title: this.training.title,
  //         nbHours: this.training.nbHours,
  //       },
  //       year: 2023,
  //     });
  //     this.submitTraining(train).then((e) => {
  //       this.closeNewTraining();
  //       console.log(e);
  //     });
  //   },
  //   confirmDeleteTraining(id) {
  //     this.$confirm.require({
  //       message: 'Voulez-vous supprimer la formation ?',
  //       header: 'Confirmation de suppression',
  //       icon: 'pi pi-info-circle',
  //       acceptLabel: 'Oui',
  //       rejectLabel: 'Non',
  //       acceptClass: 'p-button-danger',
  //       accept: () => {
  //         // this.$toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000});
  //         this.deleteTraining({ trainingId: id });
  //       },
  //       reject: () => {
  //         // this.$toast.add({severity:'info', summary:'Operation annul', detail:'You have rejected', life: 3000});
  //       },
  //     });
  //   },
  //   closeNewTraining() {
  //     this.displayEditModal = false;

  //     this.training.id = 0;
  //     this.training.nbHours = 0;
  //     this.training.title = '';
  //   },
  //   closeEditModal() {
  //     this.displayEditModal = false;
  //   },
  //   gotoSalaryStep() {
  //     this.$router.push('/local-content/step/salary');
  //   },
  //   gotoSupplyStep() {
  //     this.saveAnswers();
  //     this.$router.push('/local-content/step/supply');
  //   },
  //   openEditModal(data) {
  //     this.displayEditModal = true;
  //     this.displayEditModalData = data;
  //     const { id, title, nbHours } = data;
  //     this.training.id = id;
  //     this.training.title = title;
  //     this.training.nbHours = nbHours;
  //   },
  //   openCreateModal() {
  //     this.displayEditModal = true;
  //     this.displayEditModalData = null;
  //   },
  //   formatCurrency(value) {
  //     return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
  //   },
  // },
};
</script>
<style scoped>
input[type=checkbox] {
    transform: scale(1.5);
}
.p-datatable .p-datatable-header {
  padding: 1rem 0px !important;
}
.p-fluid .p-inputnumber {
  max-width: 19em;
  margin-left: 0.5em;
}
.p-card {
  box-shadow: none;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
</style>